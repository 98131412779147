import { Injectable } from '@angular/core';
import { ApiService } from '../../core/services/api/api.service';
import { AddDepartmentRequestBodyI } from './interfaces/add-department-request-body.interface';
import { DeleteDepartmentRequestQuery } from './interfaces/delete-department-request-query.interface';
import { DepartmentI } from './interfaces/department.interface';
import { EditDepartmentRequestBodyI } from './interfaces/edit-department-request-body.interface';
import { FilterDepartmentsI } from './interfaces/filter-departments.interface';
import { DepartmentsCardI } from './interfaces/departments-card.interface';

@Injectable({
  providedIn: 'root',
})
export class DepartmentsApiService {
  constructor(private readonly apiService: ApiService) {}

  findAllForFaculty(facultyID: string, filterDepartments: FilterDepartmentsI) {
    return this.apiService.get<DepartmentI[]>(
      `departments/faculty/${facultyID}`,
      filterDepartments
    );
  }

  findAll(filterDepartments: FilterDepartmentsI) {
    return this.apiService.get<DepartmentI[]>('departments', filterDepartments);
  }

  findOne(departmentID: string) {
    return this.apiService.get<DepartmentI>(`departments/${departmentID}`);
  }

  addDepartment(
    addDepartmentRequestBody: AddDepartmentRequestBodyI,
    facultyID: string
  ) {
    return this.apiService.post<DepartmentI>(
      `departments/${facultyID}`,
      addDepartmentRequestBody
    );
  }

  editDepartment(
    departmentID: string,
    editDepartmentRequestBody: EditDepartmentRequestBodyI
  ) {
    return this.apiService.patch<DepartmentI>(
      `departments/${departmentID}`,
      editDepartmentRequestBody
    );
  }

  deleteDepartment(
    departmentID: string,
    deleteDepartmentRequestQuery: DeleteDepartmentRequestQuery
  ) {
    return this.apiService.delete<DepartmentI>(
      `departments/${departmentID}`,
      deleteDepartmentRequestQuery
    );
  }

  card() {
    return this.apiService.get<DepartmentsCardI>(`departments/card`);
  }
}
